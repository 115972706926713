import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'homepage',
    loadChildren: () => import('./homepage/homepage.module').then(m => m.HomepageModule),
    data: { label: 'Homepage' },
  },
  {
    path: 'about-us2',
    loadChildren: () => import('./about-us2/about-us.module').then(m => m.AboutUsModule),
    data: { label: 'About Us' },
  },
  // {
  //   path: 'get-started',
  //   loadChildren: () => import('./get-started/get-started.module').then(m => m.GetStartedModule),
  //   data: { label: 'Get Started' },
  // },
  // {
  //   path: 'about-us',
  //   loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
  //   data: { label: 'About Us' },
  // },
  // {
  //   path: 'cheat-sheet',
  //   loadChildren: () => import('./cheat-sheet/cheat-sheet.module').then(m => m.CheatSheetModule),
  //   data: { label: 'Cheat Sheet' },
  // },
  // {
  //   path: 'plugins',
  //   loadChildren: () => import('./plugins/plugins.module').then(m => m.PluginsModule),
  //   data: { label: 'Plugins' },
  // },
  // {
  //   path: 're-render',
  //   loadChildren: () => import('./rerender/rerender.module').then(m => m.RerenderModule),
  //   data: { label: 'Re-render' },
  // },
  {
    path: 'whitepaper',
    loadChildren: () => import('./whitepaper/whitepaper.module').then(m => m.WhitepaperModule),
    data: { label: 'Whitepaper' },
  },
  {
    path: '**',
    redirectTo: 'homepage',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
