<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h1>Crimson Directory Services</h1>
    <span fxFlex></span>
    <button mat-icon-button (click)="toggleTheme()">
      <mat-icon>
        <img [hidden]="theme === 'light'" src="assets/icon-light-on.svg">
        <img [hidden]="theme === 'dark'" src="assets/icon-light-off.svg">
      </mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" backgroundColor="primary" disableRipple fitInkBarToContent #tabHeader>
  <a mat-tab-link *ngFor="let route of routes" #tabLink="routerLinkActive"
    [active]="tabLink.isActive"
    [routerLink]="route.path"
    routerLinkActive>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <img  src="assets/icon-{{route.path}}.svg" width="25px" height="25px">
      <span>{{ route.data?.['label'] }}</span>
    </div>
  </a>
</nav>

<mat-tab-nav-panel class="outlet-wrapper" [@routeAnimation]="getRouteAnimation(outlet)" (@routeAnimation.Done)="handleFragment()" #tabPanel>
  <router-outlet #outlet="outlet"></router-outlet>
</mat-tab-nav-panel>